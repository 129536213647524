import React from "react"
import HeaderColor from "../components/headerColor"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <HeaderColor/>
    <SEO title="404: Not found" />
    <p>No encontramos esta pagina, lo siento mucho.</p>
  </Layout>
)

export default NotFoundPage
